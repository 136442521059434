<template>
    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
        <v-card class="text-center w-full error-page pa-3">
            <div class="display-2 ma-2">{{ $t('views.error.index.title') }}</div>
            <div v-if="errorMessage" class="ma-2">
                <div>{{ errorMessage.error }}</div>
                <div>{{ errorMessage.errorDescription }}</div>
                <div>{{ errorMessage.clientId }}</div>
            </div>
        </v-card>
    </div>
</template>

<style scoped>
.error-page {
  max-width: 500px;
}
</style>

<script>
import utils from '@/utils';
import { getErrorMessage } from '@/api/app';

export default {
    data () {
        return {
            errorMessage: null
        };
    },
    async created () {
        var errorId = utils.route.getQueryString(this.$route, 'errorId');
        this.errorMessage = await getErrorMessage({ errorId });
    }
};
</script>
